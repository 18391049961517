import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7f6d423a = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _66a7b158 = () => interopDefault(import('../pages/accept-invitation.vue' /* webpackChunkName: "pages/accept-invitation" */))
const _d1df9882 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _70a8896c = () => interopDefault(import('../pages/bloomberg-2024.vue' /* webpackChunkName: "pages/bloomberg-2024" */))
const _3d940c46 = () => interopDefault(import('../pages/brand.vue' /* webpackChunkName: "pages/brand" */))
const _6d2924e9 = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _0f41ede0 = () => interopDefault(import('../pages/brokers.vue' /* webpackChunkName: "pages/brokers" */))
const _a773026a = () => interopDefault(import('../pages/brokers/settings/general.vue' /* webpackChunkName: "pages/brokers/settings/general" */))
const _147c268b = () => interopDefault(import('../pages/brokers/settings/notifications.vue' /* webpackChunkName: "pages/brokers/settings/notifications" */))
const _022baf8c = () => interopDefault(import('../pages/buyers.vue' /* webpackChunkName: "pages/buyers" */))
const _9ebbc986 = () => interopDefault(import('../pages/buyers/index.vue' /* webpackChunkName: "pages/buyers/index" */))
const _764da034 = () => interopDefault(import('../pages/buyers/analytics/index.vue' /* webpackChunkName: "pages/buyers/analytics/index" */))
const _2a25b38d = () => interopDefault(import('../pages/buyers/orders/index.vue' /* webpackChunkName: "pages/buyers/orders/index" */))
const _5da9e695 = () => interopDefault(import('../pages/buyers/payments/index.vue' /* webpackChunkName: "pages/buyers/payments/index" */))
const _492ee5b7 = () => interopDefault(import('../pages/buyers/samples/index.vue' /* webpackChunkName: "pages/buyers/samples/index" */))
const _1e9f580b = () => interopDefault(import('../pages/buyers/settings/index.vue' /* webpackChunkName: "pages/buyers/settings/index" */))
const _8c48df9c = () => interopDefault(import('../pages/buyers/payments/credit-memos.vue' /* webpackChunkName: "pages/buyers/payments/credit-memos" */))
const _30eebc18 = () => interopDefault(import('../pages/buyers/payments/statements/index.vue' /* webpackChunkName: "pages/buyers/payments/statements/index" */))
const _176c58e1 = () => interopDefault(import('../pages/buyers/settings/general.vue' /* webpackChunkName: "pages/buyers/settings/general" */))
const _47cebaa1 = () => interopDefault(import('../pages/buyers/settings/invite-colleagues.vue' /* webpackChunkName: "pages/buyers/settings/invite-colleagues" */))
const _6a1f9821 = () => interopDefault(import('../pages/buyers/settings/notifications.vue' /* webpackChunkName: "pages/buyers/settings/notifications" */))
const _f18c7a02 = () => interopDefault(import('../pages/buyers/settings/payment.vue' /* webpackChunkName: "pages/buyers/settings/payment" */))
const _715e8a86 = () => interopDefault(import('../pages/buyers/orders/list/_type.vue' /* webpackChunkName: "pages/buyers/orders/list/_type" */))
const _44a6560a = () => interopDefault(import('../pages/buyers/orders/pre-order/_id.vue' /* webpackChunkName: "pages/buyers/orders/pre-order/_id" */))
const _3fe252d3 = () => interopDefault(import('../pages/buyers/samples/list/_type.vue' /* webpackChunkName: "pages/buyers/samples/list/_type" */))
const _5a959596 = () => interopDefault(import('../pages/buyers/orders/_id.vue' /* webpackChunkName: "pages/buyers/orders/_id" */))
const _a84e2c42 = () => interopDefault(import('../pages/buyers/samples/_id.vue' /* webpackChunkName: "pages/buyers/samples/_id" */))
const _7fc2007e = () => interopDefault(import('../pages/capital/index.vue' /* webpackChunkName: "pages/capital/index" */))
const _bc2d0eea = () => interopDefault(import('../pages/careers.vue' /* webpackChunkName: "pages/careers" */))
const _c480b28c = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _160ab640 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _03ac17dc = () => interopDefault(import('../pages/claim.vue' /* webpackChunkName: "pages/claim" */))
const _6a7fa15e = () => interopDefault(import('../pages/claims.vue' /* webpackChunkName: "pages/claims" */))
const _27bd916c = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _727a56ef = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _05df6e7f = () => interopDefault(import('../pages/faq/brands.vue' /* webpackChunkName: "pages/faq/brands" */))
const _0bfa0ec0 = () => interopDefault(import('../pages/faq/retailers.vue' /* webpackChunkName: "pages/faq/retailers" */))
const _2b253ca6 = () => interopDefault(import('../pages/favorites.vue' /* webpackChunkName: "pages/favorites" */))
const _39ec14c5 = () => interopDefault(import('../pages/growth-deck.vue' /* webpackChunkName: "pages/growth-deck" */))
const _698d3aa0 = () => interopDefault(import('../pages/head-buyer.vue' /* webpackChunkName: "pages/head-buyer" */))
const _503b269a = () => interopDefault(import('../pages/head-buyer/analytics/index.vue' /* webpackChunkName: "pages/head-buyer/analytics/index" */))
const _6e5510fe = () => interopDefault(import('../pages/head-buyer/fill-rate/index.vue' /* webpackChunkName: "pages/head-buyer/fill-rate/index" */))
const _41b5f870 = () => interopDefault(import('../pages/head-buyer/inventory-velocity/index.vue' /* webpackChunkName: "pages/head-buyer/inventory-velocity/index" */))
const _12239232 = () => interopDefault(import('../pages/head-buyer/orders/index.vue' /* webpackChunkName: "pages/head-buyer/orders/index" */))
const _1a5efb6f = () => interopDefault(import('../pages/head-buyer/payments/index.vue' /* webpackChunkName: "pages/head-buyer/payments/index" */))
const _1576cd9d = () => interopDefault(import('../pages/head-buyer/samples/index.vue' /* webpackChunkName: "pages/head-buyer/samples/index" */))
const _49572636 = () => interopDefault(import('../pages/head-buyer/settings/index.vue' /* webpackChunkName: "pages/head-buyer/settings/index" */))
const _803878a8 = () => interopDefault(import('../pages/head-buyer/analytics/avg-drop-size.vue' /* webpackChunkName: "pages/head-buyer/analytics/avg-drop-size" */))
const _35f9a3e3 = () => interopDefault(import('../pages/head-buyer/analytics/avg-order-size.vue' /* webpackChunkName: "pages/head-buyer/analytics/avg-order-size" */))
const _d010188c = () => interopDefault(import('../pages/head-buyer/analytics/best-selling-brands.vue' /* webpackChunkName: "pages/head-buyer/analytics/best-selling-brands" */))
const _0d1b0cb2 = () => interopDefault(import('../pages/head-buyer/analytics/fill-rate.vue' /* webpackChunkName: "pages/head-buyer/analytics/fill-rate" */))
const _6c5d12ff = () => interopDefault(import('../pages/head-buyer/analytics/number-of-orders.vue' /* webpackChunkName: "pages/head-buyer/analytics/number-of-orders" */))
const _05a55ceb = () => interopDefault(import('../pages/head-buyer/analytics/sale-velocity.vue' /* webpackChunkName: "pages/head-buyer/analytics/sale-velocity" */))
const _55f1029a = () => interopDefault(import('../pages/head-buyer/analytics/stock-report.vue' /* webpackChunkName: "pages/head-buyer/analytics/stock-report" */))
const _30ca2a16 = () => interopDefault(import('../pages/head-buyer/orders/create-multiple.vue' /* webpackChunkName: "pages/head-buyer/orders/create-multiple" */))
const _44c83218 = () => interopDefault(import('../pages/head-buyer/payments/credit-memos.vue' /* webpackChunkName: "pages/head-buyer/payments/credit-memos" */))
const _05e5e0da = () => interopDefault(import('../pages/head-buyer/payments/statements/index.vue' /* webpackChunkName: "pages/head-buyer/payments/statements/index" */))
const _7b2f9f3b = () => interopDefault(import('../pages/head-buyer/settings/general.vue' /* webpackChunkName: "pages/head-buyer/settings/general" */))
const _236a4138 = () => interopDefault(import('../pages/head-buyer/orders/pre-order/_id.vue' /* webpackChunkName: "pages/head-buyer/orders/pre-order/_id" */))
const _80252aa6 = () => interopDefault(import('../pages/head-buyer/samples/list/_type.vue' /* webpackChunkName: "pages/head-buyer/samples/list/_type" */))
const _029ac70f = () => interopDefault(import('../pages/head-buyer/orders/_id.vue' /* webpackChunkName: "pages/head-buyer/orders/_id" */))
const _78a59345 = () => interopDefault(import('../pages/head-buyer/samples/_id.vue' /* webpackChunkName: "pages/head-buyer/samples/_id" */))
const _b75e9192 = () => interopDefault(import('../pages/help-center.vue' /* webpackChunkName: "pages/help-center" */))
const _2deeb87a = () => interopDefault(import('../pages/help-center/index.vue' /* webpackChunkName: "pages/help-center/index" */))
const _3ba73030 = () => interopDefault(import('../pages/help-center/search.vue' /* webpackChunkName: "pages/help-center/search" */))
const _2c381732 = () => interopDefault(import('../pages/help-center/_slug.vue' /* webpackChunkName: "pages/help-center/_slug" */))
const _acae4682 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _4e4bbf9e = () => interopDefault(import('../pages/lp.vue' /* webpackChunkName: "pages/lp" */))
const _abb4d0be = () => interopDefault(import('../pages/lp/index.vue' /* webpackChunkName: "pages/lp/index" */))
const _071fbfab = () => interopDefault(import('../pages/lp/inventory.vue' /* webpackChunkName: "pages/lp/inventory" */))
const _23aa99ee = () => interopDefault(import('../pages/lp/inventory/index.vue' /* webpackChunkName: "pages/lp/inventory/index" */))
const _6c2cbe00 = () => interopDefault(import('../pages/lp/inventory/all/index.vue' /* webpackChunkName: "pages/lp/inventory/all/index" */))
const _93ff7698 = () => interopDefault(import('../pages/lp/inventory/dispose-donate/index.vue' /* webpackChunkName: "pages/lp/inventory/dispose-donate/index" */))
const _37d01a50 = () => interopDefault(import('../pages/lp/inventory/inbound/index.vue' /* webpackChunkName: "pages/lp/inventory/inbound/index" */))
const _7d78b37b = () => interopDefault(import('../pages/lp/inventory/withdrawal-requests/index.vue' /* webpackChunkName: "pages/lp/inventory/withdrawal-requests/index" */))
const _5921b84e = () => interopDefault(import('../pages/lp/inventory/all/new.vue' /* webpackChunkName: "pages/lp/inventory/all/new" */))
const _456be368 = () => interopDefault(import('../pages/lp/inventory/all/_id.vue' /* webpackChunkName: "pages/lp/inventory/all/_id" */))
const _267fe41c = () => interopDefault(import('../pages/lp/inventory/dispose-donate/_id.vue' /* webpackChunkName: "pages/lp/inventory/dispose-donate/_id" */))
const _6e34cb40 = () => interopDefault(import('../pages/lp/inventory/inbound/_id.vue' /* webpackChunkName: "pages/lp/inventory/inbound/_id" */))
const _fdf94eba = () => interopDefault(import('../pages/lp/inventory/withdrawal-requests/_id.vue' /* webpackChunkName: "pages/lp/inventory/withdrawal-requests/_id" */))
const _a61782ae = () => interopDefault(import('../pages/lp/orders/index.vue' /* webpackChunkName: "pages/lp/orders/index" */))
const _4a36f0ca = () => interopDefault(import('../pages/lp/pod-freight.vue' /* webpackChunkName: "pages/lp/pod-freight" */))
const _1a8b9066 = () => interopDefault(import('../pages/lp/pod-freight/index.vue' /* webpackChunkName: "pages/lp/pod-freight/index" */))
const _131dc975 = () => interopDefault(import('../pages/lp/pod-freight/_id.vue' /* webpackChunkName: "pages/lp/pod-freight/_id" */))
const _27f78c27 = () => interopDefault(import('../pages/lp/settings/index.vue' /* webpackChunkName: "pages/lp/settings/index" */))
const _2b87f5fd = () => interopDefault(import('../pages/lp/settings/general.vue' /* webpackChunkName: "pages/lp/settings/general" */))
const _d2b8515e = () => interopDefault(import('../pages/lp/orders/_id.vue' /* webpackChunkName: "pages/lp/orders/_id" */))
const _1a606093 = () => interopDefault(import('../pages/order-guide.vue' /* webpackChunkName: "pages/order-guide" */))
const _7c575788 = () => interopDefault(import('../pages/ordered-variants.vue' /* webpackChunkName: "pages/ordered-variants" */))
const _5fc6f0bd = () => interopDefault(import('../pages/pod-3pl.vue' /* webpackChunkName: "pages/pod-3pl" */))
const _d8c69cde = () => interopDefault(import('../pages/pod-freight.vue' /* webpackChunkName: "pages/pod-freight" */))
const _032b767d = () => interopDefault(import('../pages/pod-insights.vue' /* webpackChunkName: "pages/pod-insights" */))
const _3c991016 = () => interopDefault(import('../pages/pod-pax.vue' /* webpackChunkName: "pages/pod-pax" */))
const _45f794cc = () => interopDefault(import('../pages/podcasts/index.vue' /* webpackChunkName: "pages/podcasts/index" */))
const _4c2ed6ce = () => interopDefault(import('../pages/press.vue' /* webpackChunkName: "pages/press" */))
const _4f29009f = () => interopDefault(import('../pages/pricing/index.vue' /* webpackChunkName: "pages/pricing/index" */))
const _2d1611de = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _7a91afc5 = () => interopDefault(import('../pages/product.vue' /* webpackChunkName: "pages/product" */))
const _00387a01 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _76ff1b6c = () => interopDefault(import('../pages/promotions.vue' /* webpackChunkName: "pages/promotions" */))
const _1584a930 = () => interopDefault(import('../pages/recommended-products.vue' /* webpackChunkName: "pages/recommended-products" */))
const _19d0cfb7 = () => interopDefault(import('../pages/referral.vue' /* webpackChunkName: "pages/referral" */))
const _30209480 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _389535f3 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _a110524e = () => interopDefault(import('../pages/retailers.vue' /* webpackChunkName: "pages/retailers" */))
const _160f18f6 = () => interopDefault(import('../pages/setup-password.vue' /* webpackChunkName: "pages/setup-password" */))
const _e8bba626 = () => interopDefault(import('../pages/state-of-grocery.vue' /* webpackChunkName: "pages/state-of-grocery" */))
const _39e78777 = () => interopDefault(import('../pages/team.vue' /* webpackChunkName: "pages/team" */))
const _53b506df = () => interopDefault(import('../pages/terms-of-service.vue' /* webpackChunkName: "pages/terms-of-service" */))
const _f49aabbc = () => interopDefault(import('../pages/terms-of-service/index.vue' /* webpackChunkName: "pages/terms-of-service/index" */))
const _59b181ac = () => interopDefault(import('../pages/terms-of-service/brands.vue' /* webpackChunkName: "pages/terms-of-service/brands" */))
const _642d6685 = () => interopDefault(import('../pages/terms-of-service/logistics-partners.vue' /* webpackChunkName: "pages/terms-of-service/logistics-partners" */))
const _45a87a53 = () => interopDefault(import('../pages/terms-of-service/retailers.vue' /* webpackChunkName: "pages/terms-of-service/retailers" */))
const _16e225b1 = () => interopDefault(import('../pages/terms-of-use.vue' /* webpackChunkName: "pages/terms-of-use" */))
const _4fd82dfe = () => interopDefault(import('../pages/vendors.vue' /* webpackChunkName: "pages/vendors" */))
const _1273eac4 = () => interopDefault(import('../pages/vendors/index.vue' /* webpackChunkName: "pages/vendors/index" */))
const _36bf2678 = () => interopDefault(import('../pages/vendors/analytics.vue' /* webpackChunkName: "pages/vendors/analytics" */))
const _0ffa1a0a = () => interopDefault(import('../pages/vendors/analytics/index.vue' /* webpackChunkName: "pages/vendors/analytics/index" */))
const _f7d9a576 = () => interopDefault(import('../pages/vendors/analytics/customer.vue' /* webpackChunkName: "pages/vendors/analytics/customer" */))
const _8fd027f0 = () => interopDefault(import('../pages/vendors/analytics/customer/index.vue' /* webpackChunkName: "pages/vendors/analytics/customer/index" */))
const _68224922 = () => interopDefault(import('../pages/vendors/analytics/customer/churn.vue' /* webpackChunkName: "pages/vendors/analytics/customer/churn" */))
const _611df3a2 = () => interopDefault(import('../pages/vendors/analytics/customer/cohort.vue' /* webpackChunkName: "pages/vendors/analytics/customer/cohort" */))
const _31b05333 = () => interopDefault(import('../pages/vendors/analytics/customer/first-time-and-returning.vue' /* webpackChunkName: "pages/vendors/analytics/customer/first-time-and-returning" */))
const _4c57d7de = () => interopDefault(import('../pages/vendors/analytics/customer/growth-accounting.vue' /* webpackChunkName: "pages/vendors/analytics/customer/growth-accounting" */))
const _5c85e80e = () => interopDefault(import('../pages/vendors/analytics/customer/one-time.vue' /* webpackChunkName: "pages/vendors/analytics/customer/one-time" */))
const _4393d348 = () => interopDefault(import('../pages/vendors/analytics/customer/over-time.vue' /* webpackChunkName: "pages/vendors/analytics/customer/over-time" */))
const _b309b842 = () => interopDefault(import('../pages/vendors/analytics/customer/sales-by-region.vue' /* webpackChunkName: "pages/vendors/analytics/customer/sales-by-region" */))
const _25cbde05 = () => interopDefault(import('../pages/vendors/analytics/inventory.vue' /* webpackChunkName: "pages/vendors/analytics/inventory" */))
const _25390cc8 = () => interopDefault(import('../pages/vendors/analytics/inventory/index.vue' /* webpackChunkName: "pages/vendors/analytics/inventory/index" */))
const _03184243 = () => interopDefault(import('../pages/vendors/analytics/inventory/average-sold-per-week.vue' /* webpackChunkName: "pages/vendors/analytics/inventory/average-sold-per-week" */))
const _64405322 = () => interopDefault(import('../pages/vendors/analytics/inventory/current.vue' /* webpackChunkName: "pages/vendors/analytics/inventory/current" */))
const _9e08a7a8 = () => interopDefault(import('../pages/vendors/analytics/inventory/days-of-remaining.vue' /* webpackChunkName: "pages/vendors/analytics/inventory/days-of-remaining" */))
const _5ee8fac6 = () => interopDefault(import('../pages/vendors/analytics/inventory/lost-sales.vue' /* webpackChunkName: "pages/vendors/analytics/inventory/lost-sales" */))
const _3c746704 = () => interopDefault(import('../pages/vendors/analytics/inventory/month-end.vue' /* webpackChunkName: "pages/vendors/analytics/inventory/month-end" */))
const _6cf877f8 = () => interopDefault(import('../pages/vendors/analytics/inventory/percent-of-sold.vue' /* webpackChunkName: "pages/vendors/analytics/inventory/percent-of-sold" */))
const _62f6ee8b = () => interopDefault(import('../pages/vendors/analytics/inventory/replenishment-lead-time.vue' /* webpackChunkName: "pages/vendors/analytics/inventory/replenishment-lead-time" */))
const _d6bb3b72 = () => interopDefault(import('../pages/vendors/analytics/lead-time.vue' /* webpackChunkName: "pages/vendors/analytics/lead-time" */))
const _151050ec = () => interopDefault(import('../pages/vendors/analytics/lead-time/index.vue' /* webpackChunkName: "pages/vendors/analytics/lead-time/index" */))
const _1aeda4be = () => interopDefault(import('../pages/vendors/analytics/lead-time/pd-order-fulfillment.vue' /* webpackChunkName: "pages/vendors/analytics/lead-time/pd-order-fulfillment" */))
const _ce116d88 = () => interopDefault(import('../pages/vendors/analytics/lead-time/replenishment.vue' /* webpackChunkName: "pages/vendors/analytics/lead-time/replenishment" */))
const _a9507468 = () => interopDefault(import('../pages/vendors/analytics/promotion.vue' /* webpackChunkName: "pages/vendors/analytics/promotion" */))
const _16187962 = () => interopDefault(import('../pages/vendors/analytics/promotion/index.vue' /* webpackChunkName: "pages/vendors/analytics/promotion/index" */))
const _a6c9e388 = () => interopDefault(import('../pages/vendors/analytics/promotion/leading-to-repurchase.vue' /* webpackChunkName: "pages/vendors/analytics/promotion/leading-to-repurchase" */))
const _533a189e = () => interopDefault(import('../pages/vendors/analytics/promotion/usage.vue' /* webpackChunkName: "pages/vendors/analytics/promotion/usage" */))
const _19ca50ae = () => interopDefault(import('../pages/vendors/analytics/sale.vue' /* webpackChunkName: "pages/vendors/analytics/sale" */))
const _15171cb1 = () => interopDefault(import('../pages/vendors/analytics/sale/index.vue' /* webpackChunkName: "pages/vendors/analytics/sale/index" */))
const _1407e856 = () => interopDefault(import('../pages/vendors/analytics/sale/avg-order-value.vue' /* webpackChunkName: "pages/vendors/analytics/sale/avg-order-value" */))
const _7c9f1b81 = () => interopDefault(import('../pages/vendors/analytics/sale/fulfilled-gmv.vue' /* webpackChunkName: "pages/vendors/analytics/sale/fulfilled-gmv" */))
const _068114cd = () => interopDefault(import('../pages/vendors/analytics/sale/fulfilled-revenue.vue' /* webpackChunkName: "pages/vendors/analytics/sale/fulfilled-revenue" */))
const _de74bae2 = () => interopDefault(import('../pages/vendors/analytics/sale/ordered-gmv.vue' /* webpackChunkName: "pages/vendors/analytics/sale/ordered-gmv" */))
const _06d164db = () => interopDefault(import('../pages/vendors/analytics/sale/ordered-revenue.vue' /* webpackChunkName: "pages/vendors/analytics/sale/ordered-revenue" */))
const _b971c8c2 = () => interopDefault(import('../pages/vendors/analytics/sale/sku-performance.vue' /* webpackChunkName: "pages/vendors/analytics/sale/sku-performance" */))
const _031e3c4d = () => interopDefault(import('../pages/vendors/brands/index.vue' /* webpackChunkName: "pages/vendors/brands/index" */))
const _663b4c78 = () => interopDefault(import('../pages/vendors/claims/index.vue' /* webpackChunkName: "pages/vendors/claims/index" */))
const _2bf9c94e = () => interopDefault(import('../pages/vendors/inventory.vue' /* webpackChunkName: "pages/vendors/inventory" */))
const _f89cc55e = () => interopDefault(import('../pages/vendors/inventory/index.vue' /* webpackChunkName: "pages/vendors/inventory/index" */))
const _c3570740 = () => interopDefault(import('../pages/vendors/inventory/all.vue' /* webpackChunkName: "pages/vendors/inventory/all" */))
const _581baef1 = () => interopDefault(import('../pages/vendors/inventory/dispose-donate/index.vue' /* webpackChunkName: "pages/vendors/inventory/dispose-donate/index" */))
const _70e2e33b = () => interopDefault(import('../pages/vendors/inventory/inbound/index.vue' /* webpackChunkName: "pages/vendors/inventory/inbound/index" */))
const _513bc8fa = () => interopDefault(import('../pages/vendors/inventory/status.vue' /* webpackChunkName: "pages/vendors/inventory/status" */))
const _6c22ce3f = () => interopDefault(import('../pages/vendors/inventory/transfer/index.vue' /* webpackChunkName: "pages/vendors/inventory/transfer/index" */))
const _3e07275e = () => interopDefault(import('../pages/vendors/inventory/withdrawal-requests/index.vue' /* webpackChunkName: "pages/vendors/inventory/withdrawal-requests/index" */))
const _59e30c26 = () => interopDefault(import('../pages/vendors/inventory/dispose-donate/create.vue' /* webpackChunkName: "pages/vendors/inventory/dispose-donate/create" */))
const _59a4623a = () => interopDefault(import('../pages/vendors/inventory/inbound/create.vue' /* webpackChunkName: "pages/vendors/inventory/inbound/create" */))
const _aadbe1c0 = () => interopDefault(import('../pages/vendors/inventory/withdrawal-requests/create.vue' /* webpackChunkName: "pages/vendors/inventory/withdrawal-requests/create" */))
const _3bfab999 = () => interopDefault(import('../pages/vendors/inventory/dispose-donate/_id.vue' /* webpackChunkName: "pages/vendors/inventory/dispose-donate/_id" */))
const _d6930f3a = () => interopDefault(import('../pages/vendors/inventory/inbound/_id.vue' /* webpackChunkName: "pages/vendors/inventory/inbound/_id" */))
const _53855732 = () => interopDefault(import('../pages/vendors/inventory/transfer/_id.vue' /* webpackChunkName: "pages/vendors/inventory/transfer/_id" */))
const _15536946 = () => interopDefault(import('../pages/vendors/inventory/withdrawal-requests/_id.vue' /* webpackChunkName: "pages/vendors/inventory/withdrawal-requests/_id" */))
const _76b661da = () => interopDefault(import('../pages/vendors/notifications.vue' /* webpackChunkName: "pages/vendors/notifications" */))
const _263bae26 = () => interopDefault(import('../pages/vendors/orders/index.vue' /* webpackChunkName: "pages/vendors/orders/index" */))
const _320884ab = () => interopDefault(import('../pages/vendors/payments.vue' /* webpackChunkName: "pages/vendors/payments" */))
const _a7a8c224 = () => interopDefault(import('../pages/vendors/payments/index.vue' /* webpackChunkName: "pages/vendors/payments/index" */))
const _052877ff = () => interopDefault(import('../pages/vendors/payments/commission.vue' /* webpackChunkName: "pages/vendors/payments/commission" */))
const _0b24e729 = () => interopDefault(import('../pages/vendors/payments/payouts.vue' /* webpackChunkName: "pages/vendors/payments/payouts" */))
const _ebf3300a = () => interopDefault(import('../pages/vendors/payments/statements/index.vue' /* webpackChunkName: "pages/vendors/payments/statements/index" */))
const _25756f36 = () => interopDefault(import('../pages/vendors/products/index.vue' /* webpackChunkName: "pages/vendors/products/index" */))
const _264f5eb1 = () => interopDefault(import('../pages/vendors/promotions/index.vue' /* webpackChunkName: "pages/vendors/promotions/index" */))
const _604f8384 = () => interopDefault(import('../pages/vendors/samples/index.vue' /* webpackChunkName: "pages/vendors/samples/index" */))
const _6d211064 = () => interopDefault(import('../pages/vendors/settings/index.vue' /* webpackChunkName: "pages/vendors/settings/index" */))
const _6538da9a = () => interopDefault(import('../pages/vendors/stores/index.vue' /* webpackChunkName: "pages/vendors/stores/index" */))
const _03e868be = () => interopDefault(import('../pages/vendors/widgets.vue' /* webpackChunkName: "pages/vendors/widgets" */))
const _ee3ae574 = () => interopDefault(import('../pages/vendors/claims/create.vue' /* webpackChunkName: "pages/vendors/claims/create" */))
const _39cfe20e = () => interopDefault(import('../pages/vendors/products/create.vue' /* webpackChunkName: "pages/vendors/products/create" */))
const _695e7ba6 = () => interopDefault(import('../pages/vendors/promotions/create.vue' /* webpackChunkName: "pages/vendors/promotions/create" */))
const _4347b000 = () => interopDefault(import('../pages/vendors/promotions/draft.vue' /* webpackChunkName: "pages/vendors/promotions/draft" */))
const _673d420c = () => interopDefault(import('../pages/vendors/settings/general.vue' /* webpackChunkName: "pages/vendors/settings/general" */))
const _15ec950c = () => interopDefault(import('../pages/vendors/settings/invite-colleagues.vue' /* webpackChunkName: "pages/vendors/settings/invite-colleagues" */))
const _9adfbbfa = () => interopDefault(import('../pages/vendors/settings/minimums.vue' /* webpackChunkName: "pages/vendors/settings/minimums" */))
const _6333e77a = () => interopDefault(import('../pages/vendors/settings/notifications.vue' /* webpackChunkName: "pages/vendors/settings/notifications" */))
const _4d55fb0b = () => interopDefault(import('../pages/vendors/settings/payments.vue' /* webpackChunkName: "pages/vendors/settings/payments" */))
const _3c3a4ec0 = () => interopDefault(import('../pages/vendors/orders/canceled/_id.vue' /* webpackChunkName: "pages/vendors/orders/canceled/_id" */))
const _5db8edf5 = () => interopDefault(import('../pages/vendors/brands/_id.vue' /* webpackChunkName: "pages/vendors/brands/_id" */))
const _56a20ce0 = () => interopDefault(import('../pages/vendors/claims/_id.vue' /* webpackChunkName: "pages/vendors/claims/_id" */))
const _0d0b020e = () => interopDefault(import('../pages/vendors/orders/_id.vue' /* webpackChunkName: "pages/vendors/orders/_id" */))
const _b437bfe6 = () => interopDefault(import('../pages/vendors/products/_id.vue' /* webpackChunkName: "pages/vendors/products/_id" */))
const _35c9a8d0 = () => interopDefault(import('../pages/vendors/products/_id/index.vue' /* webpackChunkName: "pages/vendors/products/_id/index" */))
const _74e356c0 = () => interopDefault(import('../pages/vendors/products/_id/request-change.vue' /* webpackChunkName: "pages/vendors/products/_id/request-change" */))
const _f2dec82a = () => interopDefault(import('../pages/vendors/products/_id/skus/index.vue' /* webpackChunkName: "pages/vendors/products/_id/skus/index" */))
const _73372c33 = () => interopDefault(import('../pages/vendors/products/_id/skus/create.vue' /* webpackChunkName: "pages/vendors/products/_id/skus/create" */))
const _52469b89 = () => interopDefault(import('../pages/vendors/products/_id/skus/_sid/index.vue' /* webpackChunkName: "pages/vendors/products/_id/skus/_sid/index" */))
const _4ed75959 = () => interopDefault(import('../pages/vendors/promotions/_id.vue' /* webpackChunkName: "pages/vendors/promotions/_id" */))
const _87868fb4 = () => interopDefault(import('../pages/vendors/samples/_id.vue' /* webpackChunkName: "pages/vendors/samples/_id" */))
const _b5f23ca6 = () => interopDefault(import('../pages/welcome-vendor.vue' /* webpackChunkName: "pages/welcome-vendor" */))
const _557c8282 = () => interopDefault(import('../pages/brands/invite.vue' /* webpackChunkName: "pages/brands/invite" */))
const _1d987efb = () => interopDefault(import('../pages/capital/plus.vue' /* webpackChunkName: "pages/capital/plus" */))
const _2f8cf8fa = () => interopDefault(import('../pages/capital/term-loans.vue' /* webpackChunkName: "pages/capital/term-loans" */))
const _f988989a = () => interopDefault(import('../pages/pricing/earnings-calculator.vue' /* webpackChunkName: "pages/pricing/earnings-calculator" */))
const _6c385a94 = () => interopDefault(import('../pages/services/brands/index.vue' /* webpackChunkName: "pages/services/brands/index" */))
const _153fc42c = () => interopDefault(import('../pages/services/retailers.vue' /* webpackChunkName: "pages/services/retailers" */))
const _56058b35 = () => interopDefault(import('../pages/services/brands/direct.vue' /* webpackChunkName: "pages/services/brands/direct" */))
const _4cf05158 = () => interopDefault(import('../pages/services/brands/express.vue' /* webpackChunkName: "pages/services/brands/express" */))
const _3ffd8cb0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _e4e5c7e0 = () => interopDefault(import('../pages/invoices/buyers/orders/_id.vue' /* webpackChunkName: "pages/invoices/buyers/orders/_id" */))
const _4bfdde64 = () => interopDefault(import('../pages/invoices/buyers/samples/_id.vue' /* webpackChunkName: "pages/invoices/buyers/samples/_id" */))
const _e653b76a = () => interopDefault(import('../pages/invoices/headBuyer/orders/_id.vue' /* webpackChunkName: "pages/invoices/headBuyer/orders/_id" */))
const _35d5dd89 = () => interopDefault(import('../pages/invoices/headBuyer/samples/_id.vue' /* webpackChunkName: "pages/invoices/headBuyer/samples/_id" */))
const _1e983450 = () => interopDefault(import('../pages/invoices/lp/order/_id/index.vue' /* webpackChunkName: "pages/invoices/lp/order/_id/index" */))
const _a5a012da = () => interopDefault(import('../pages/invoices/vendors/orders/_id.vue' /* webpackChunkName: "pages/invoices/vendors/orders/_id" */))
const _20b65441 = () => interopDefault(import('../pages/invoices/vendors/samples/_id.vue' /* webpackChunkName: "pages/invoices/vendors/samples/_id" */))
const _d64870aa = () => interopDefault(import('../pages/invoices/lp/order/_id/invoice.vue' /* webpackChunkName: "pages/invoices/lp/order/_id/invoice" */))
const _6cee1532 = () => interopDefault(import('../pages/invoices/lp/order/_id/slip.vue' /* webpackChunkName: "pages/invoices/lp/order/_id/slip" */))
const _d54cdb12 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _c14564de = () => interopDefault(import('../pages/brands/_id.vue' /* webpackChunkName: "pages/brands/_id" */))
const _0fef08a9 = () => interopDefault(import('../pages/products/_id.vue' /* webpackChunkName: "pages/products/_id" */))
const _cf6c7d96 = () => interopDefault(import('~/pages/brand' /* webpackChunkName: "" */))
const _db92e1c6 = () => interopDefault(import('~/pages/product' /* webpackChunkName: "" */))
const _224ce406 = () => interopDefault(import('~/pages/setup-password' /* webpackChunkName: "" */))
const _16c8cba8 = () => interopDefault(import('~/pages/accept-invitation' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _7f6d423a,
    name: "about"
  }, {
    path: "/accept-invitation",
    component: _66a7b158,
    name: "accept-invitation"
  }, {
    path: "/blog",
    component: _d1df9882,
    name: "blog"
  }, {
    path: "/bloomberg-2024",
    component: _70a8896c,
    name: "bloomberg-2024"
  }, {
    path: "/brand",
    component: _3d940c46,
    name: "brand"
  }, {
    path: "/brands",
    component: _6d2924e9,
    name: "brands"
  }, {
    path: "/brokers",
    component: _0f41ede0,
    name: "brokers",
    children: [{
      path: "settings/general",
      component: _a773026a,
      name: "brokers-settings-general"
    }, {
      path: "settings/notifications",
      component: _147c268b,
      name: "brokers-settings-notifications"
    }]
  }, {
    path: "/buyers",
    component: _022baf8c,
    children: [{
      path: "",
      component: _9ebbc986,
      name: "buyers"
    }, {
      path: "analytics",
      component: _764da034,
      name: "buyers-analytics"
    }, {
      path: "orders",
      component: _2a25b38d,
      name: "buyers-orders"
    }, {
      path: "payments",
      component: _5da9e695,
      name: "buyers-payments"
    }, {
      path: "samples",
      component: _492ee5b7,
      name: "buyers-samples"
    }, {
      path: "settings",
      component: _1e9f580b,
      name: "buyers-settings"
    }, {
      path: "payments/credit-memos",
      component: _8c48df9c,
      name: "buyers-payments-credit-memos"
    }, {
      path: "payments/statements",
      component: _30eebc18,
      name: "buyers-payments-statements"
    }, {
      path: "settings/general",
      component: _176c58e1,
      name: "buyers-settings-general"
    }, {
      path: "settings/invite-colleagues",
      component: _47cebaa1,
      name: "buyers-settings-invite-colleagues"
    }, {
      path: "settings/notifications",
      component: _6a1f9821,
      name: "buyers-settings-notifications"
    }, {
      path: "settings/payment",
      component: _f18c7a02,
      name: "buyers-settings-payment"
    }, {
      path: "orders/list/:type?",
      component: _715e8a86,
      name: "buyers-orders-list-type"
    }, {
      path: "orders/pre-order/:id?",
      component: _44a6560a,
      name: "buyers-orders-pre-order-id"
    }, {
      path: "samples/list/:type?",
      component: _3fe252d3,
      name: "buyers-samples-list-type"
    }, {
      path: "orders/:id",
      component: _5a959596,
      name: "buyers-orders-id"
    }, {
      path: "samples/:id",
      component: _a84e2c42,
      name: "buyers-samples-id"
    }]
  }, {
    path: "/capital",
    component: _7fc2007e,
    name: "capital"
  }, {
    path: "/careers",
    component: _bc2d0eea,
    name: "careers"
  }, {
    path: "/cart",
    component: _c480b28c,
    name: "cart"
  }, {
    path: "/checkout",
    component: _160ab640,
    name: "checkout"
  }, {
    path: "/claim",
    component: _03ac17dc,
    name: "claim"
  }, {
    path: "/claims",
    component: _6a7fa15e,
    name: "claims"
  }, {
    path: "/faq",
    component: _27bd916c,
    children: [{
      path: "",
      component: _727a56ef,
      name: "faq"
    }, {
      path: "brands",
      component: _05df6e7f,
      name: "faq-brands"
    }, {
      path: "retailers",
      component: _0bfa0ec0,
      name: "faq-retailers"
    }]
  }, {
    path: "/favorites",
    component: _2b253ca6,
    name: "favorites"
  }, {
    path: "/growth-deck",
    component: _39ec14c5,
    name: "growth-deck"
  }, {
    path: "/head-buyer",
    component: _698d3aa0,
    name: "head-buyer",
    children: [{
      path: "analytics",
      component: _503b269a,
      name: "head-buyer-analytics"
    }, {
      path: "fill-rate",
      component: _6e5510fe,
      name: "head-buyer-fill-rate"
    }, {
      path: "inventory-velocity",
      component: _41b5f870,
      name: "head-buyer-inventory-velocity"
    }, {
      path: "orders",
      component: _12239232,
      name: "head-buyer-orders"
    }, {
      path: "payments",
      component: _1a5efb6f,
      name: "head-buyer-payments"
    }, {
      path: "samples",
      component: _1576cd9d,
      name: "head-buyer-samples"
    }, {
      path: "settings",
      component: _49572636,
      name: "head-buyer-settings"
    }, {
      path: "analytics/avg-drop-size",
      component: _803878a8,
      name: "head-buyer-analytics-avg-drop-size"
    }, {
      path: "analytics/avg-order-size",
      component: _35f9a3e3,
      name: "head-buyer-analytics-avg-order-size"
    }, {
      path: "analytics/best-selling-brands",
      component: _d010188c,
      name: "head-buyer-analytics-best-selling-brands"
    }, {
      path: "analytics/fill-rate",
      component: _0d1b0cb2,
      name: "head-buyer-analytics-fill-rate"
    }, {
      path: "analytics/number-of-orders",
      component: _6c5d12ff,
      name: "head-buyer-analytics-number-of-orders"
    }, {
      path: "analytics/sale-velocity",
      component: _05a55ceb,
      name: "head-buyer-analytics-sale-velocity"
    }, {
      path: "analytics/stock-report",
      component: _55f1029a,
      name: "head-buyer-analytics-stock-report"
    }, {
      path: "orders/create-multiple",
      component: _30ca2a16,
      name: "head-buyer-orders-create-multiple"
    }, {
      path: "payments/credit-memos",
      component: _44c83218,
      name: "head-buyer-payments-credit-memos"
    }, {
      path: "payments/statements",
      component: _05e5e0da,
      name: "head-buyer-payments-statements"
    }, {
      path: "settings/general",
      component: _7b2f9f3b,
      name: "head-buyer-settings-general"
    }, {
      path: "orders/pre-order/:id?",
      component: _236a4138,
      name: "head-buyer-orders-pre-order-id"
    }, {
      path: "samples/list/:type?",
      component: _80252aa6,
      name: "head-buyer-samples-list-type"
    }, {
      path: "orders/:id",
      component: _029ac70f,
      name: "head-buyer-orders-id"
    }, {
      path: "samples/:id",
      component: _78a59345,
      name: "head-buyer-samples-id"
    }]
  }, {
    path: "/help-center",
    component: _b75e9192,
    children: [{
      path: "",
      component: _2deeb87a,
      name: "help-center"
    }, {
      path: "search",
      component: _3ba73030,
      name: "help-center-search"
    }, {
      path: ":slug",
      component: _2c381732,
      name: "help-center-slug"
    }]
  }, {
    path: "/login",
    component: _acae4682,
    name: "login"
  }, {
    path: "/lp",
    component: _4e4bbf9e,
    children: [{
      path: "",
      component: _abb4d0be,
      name: "lp"
    }, {
      path: "inventory",
      component: _071fbfab,
      children: [{
        path: "",
        component: _23aa99ee,
        name: "lp-inventory"
      }, {
        path: "all",
        component: _6c2cbe00,
        name: "lp-inventory-all"
      }, {
        path: "dispose-donate",
        component: _93ff7698,
        name: "lp-inventory-dispose-donate"
      }, {
        path: "inbound",
        component: _37d01a50,
        name: "lp-inventory-inbound"
      }, {
        path: "withdrawal-requests",
        component: _7d78b37b,
        name: "lp-inventory-withdrawal-requests"
      }, {
        path: "all/new",
        component: _5921b84e,
        name: "lp-inventory-all-new"
      }, {
        path: "all/:id",
        component: _456be368,
        name: "lp-inventory-all-id"
      }, {
        path: "dispose-donate/:id?",
        component: _267fe41c,
        name: "lp-inventory-dispose-donate-id"
      }, {
        path: "inbound/:id",
        component: _6e34cb40,
        name: "lp-inventory-inbound-id"
      }, {
        path: "withdrawal-requests/:id?",
        component: _fdf94eba,
        name: "lp-inventory-withdrawal-requests-id"
      }]
    }, {
      path: "orders",
      component: _a61782ae,
      name: "lp-orders"
    }, {
      path: "pod-freight",
      component: _4a36f0ca,
      children: [{
        path: "",
        component: _1a8b9066,
        name: "lp-pod-freight"
      }, {
        path: ":id",
        component: _131dc975,
        name: "lp-pod-freight-id"
      }]
    }, {
      path: "settings",
      component: _27f78c27,
      name: "lp-settings"
    }, {
      path: "settings/general",
      component: _2b87f5fd,
      name: "lp-settings-general"
    }, {
      path: "orders/:id",
      component: _d2b8515e,
      name: "lp-orders-id"
    }]
  }, {
    path: "/order-guide",
    component: _1a606093,
    name: "order-guide"
  }, {
    path: "/ordered-variants",
    component: _7c575788,
    name: "ordered-variants"
  }, {
    path: "/pod-3pl",
    component: _5fc6f0bd,
    name: "pod-3pl"
  }, {
    path: "/pod-freight",
    component: _d8c69cde,
    name: "pod-freight"
  }, {
    path: "/pod-insights",
    component: _032b767d,
    name: "pod-insights"
  }, {
    path: "/pod-pax",
    component: _3c991016,
    name: "pod-pax"
  }, {
    path: "/podcasts",
    component: _45f794cc,
    name: "podcasts"
  }, {
    path: "/press",
    component: _4c2ed6ce,
    name: "press"
  }, {
    path: "/pricing",
    component: _4f29009f,
    name: "pricing"
  }, {
    path: "/privacy",
    component: _2d1611de,
    name: "privacy"
  }, {
    path: "/product",
    component: _7a91afc5,
    name: "product"
  }, {
    path: "/products",
    component: _00387a01,
    name: "products"
  }, {
    path: "/promotions",
    component: _76ff1b6c,
    name: "promotions"
  }, {
    path: "/recommended-products",
    component: _1584a930,
    name: "recommended-products"
  }, {
    path: "/referral",
    component: _19d0cfb7,
    name: "referral"
  }, {
    path: "/register",
    component: _30209480,
    name: "register"
  }, {
    path: "/reset-password",
    component: _389535f3,
    name: "reset-password"
  }, {
    path: "/retailers",
    component: _a110524e,
    name: "retailers"
  }, {
    path: "/setup-password",
    component: _160f18f6,
    name: "setup-password"
  }, {
    path: "/state-of-grocery",
    component: _e8bba626,
    name: "state-of-grocery"
  }, {
    path: "/team",
    component: _39e78777,
    name: "team"
  }, {
    path: "/terms-of-service",
    component: _53b506df,
    children: [{
      path: "",
      component: _f49aabbc,
      name: "terms-of-service"
    }, {
      path: "brands",
      component: _59b181ac,
      name: "terms-of-service-brands"
    }, {
      path: "logistics-partners",
      component: _642d6685,
      name: "terms-of-service-logistics-partners"
    }, {
      path: "retailers",
      component: _45a87a53,
      name: "terms-of-service-retailers"
    }]
  }, {
    path: "/terms-of-use",
    component: _16e225b1,
    name: "terms-of-use"
  }, {
    path: "/vendors",
    component: _4fd82dfe,
    children: [{
      path: "",
      component: _1273eac4,
      name: "vendors"
    }, {
      path: "analytics",
      component: _36bf2678,
      children: [{
        path: "",
        component: _0ffa1a0a,
        name: "vendors-analytics"
      }, {
        path: "customer",
        component: _f7d9a576,
        children: [{
          path: "",
          component: _8fd027f0,
          name: "vendors-analytics-customer"
        }, {
          path: "churn",
          component: _68224922,
          name: "vendors-analytics-customer-churn"
        }, {
          path: "cohort",
          component: _611df3a2,
          name: "vendors-analytics-customer-cohort"
        }, {
          path: "first-time-and-returning",
          component: _31b05333,
          name: "vendors-analytics-customer-first-time-and-returning"
        }, {
          path: "growth-accounting",
          component: _4c57d7de,
          name: "vendors-analytics-customer-growth-accounting"
        }, {
          path: "one-time",
          component: _5c85e80e,
          name: "vendors-analytics-customer-one-time"
        }, {
          path: "over-time",
          component: _4393d348,
          name: "vendors-analytics-customer-over-time"
        }, {
          path: "sales-by-region",
          component: _b309b842,
          name: "vendors-analytics-customer-sales-by-region"
        }]
      }, {
        path: "inventory",
        component: _25cbde05,
        children: [{
          path: "",
          component: _25390cc8,
          name: "vendors-analytics-inventory"
        }, {
          path: "average-sold-per-week",
          component: _03184243,
          name: "vendors-analytics-inventory-average-sold-per-week"
        }, {
          path: "current",
          component: _64405322,
          name: "vendors-analytics-inventory-current"
        }, {
          path: "days-of-remaining",
          component: _9e08a7a8,
          name: "vendors-analytics-inventory-days-of-remaining"
        }, {
          path: "lost-sales",
          component: _5ee8fac6,
          name: "vendors-analytics-inventory-lost-sales"
        }, {
          path: "month-end",
          component: _3c746704,
          name: "vendors-analytics-inventory-month-end"
        }, {
          path: "percent-of-sold",
          component: _6cf877f8,
          name: "vendors-analytics-inventory-percent-of-sold"
        }, {
          path: "replenishment-lead-time",
          component: _62f6ee8b,
          name: "vendors-analytics-inventory-replenishment-lead-time"
        }]
      }, {
        path: "lead-time",
        component: _d6bb3b72,
        children: [{
          path: "",
          component: _151050ec,
          name: "vendors-analytics-lead-time"
        }, {
          path: "pd-order-fulfillment",
          component: _1aeda4be,
          name: "vendors-analytics-lead-time-pd-order-fulfillment"
        }, {
          path: "replenishment",
          component: _ce116d88,
          name: "vendors-analytics-lead-time-replenishment"
        }]
      }, {
        path: "promotion",
        component: _a9507468,
        children: [{
          path: "",
          component: _16187962,
          name: "vendors-analytics-promotion"
        }, {
          path: "leading-to-repurchase",
          component: _a6c9e388,
          name: "vendors-analytics-promotion-leading-to-repurchase"
        }, {
          path: "usage",
          component: _533a189e,
          name: "vendors-analytics-promotion-usage"
        }]
      }, {
        path: "sale",
        component: _19ca50ae,
        children: [{
          path: "",
          component: _15171cb1,
          name: "vendors-analytics-sale"
        }, {
          path: "avg-order-value",
          component: _1407e856,
          name: "vendors-analytics-sale-avg-order-value"
        }, {
          path: "fulfilled-gmv",
          component: _7c9f1b81,
          name: "vendors-analytics-sale-fulfilled-gmv"
        }, {
          path: "fulfilled-revenue",
          component: _068114cd,
          name: "vendors-analytics-sale-fulfilled-revenue"
        }, {
          path: "ordered-gmv",
          component: _de74bae2,
          name: "vendors-analytics-sale-ordered-gmv"
        }, {
          path: "ordered-revenue",
          component: _06d164db,
          name: "vendors-analytics-sale-ordered-revenue"
        }, {
          path: "sku-performance",
          component: _b971c8c2,
          name: "vendors-analytics-sale-sku-performance"
        }]
      }]
    }, {
      path: "brands",
      component: _031e3c4d,
      name: "vendors-brands"
    }, {
      path: "claims",
      component: _663b4c78,
      name: "vendors-claims"
    }, {
      path: "inventory",
      component: _2bf9c94e,
      children: [{
        path: "",
        component: _f89cc55e,
        name: "vendors-inventory"
      }, {
        path: "all",
        component: _c3570740,
        name: "vendors-inventory-all"
      }, {
        path: "dispose-donate",
        component: _581baef1,
        name: "vendors-inventory-dispose-donate"
      }, {
        path: "inbound",
        component: _70e2e33b,
        name: "vendors-inventory-inbound"
      }, {
        path: "status",
        component: _513bc8fa,
        name: "vendors-inventory-status"
      }, {
        path: "transfer",
        component: _6c22ce3f,
        name: "vendors-inventory-transfer"
      }, {
        path: "withdrawal-requests",
        component: _3e07275e,
        name: "vendors-inventory-withdrawal-requests"
      }, {
        path: "dispose-donate/create",
        component: _59e30c26,
        name: "vendors-inventory-dispose-donate-create"
      }, {
        path: "inbound/create",
        component: _59a4623a,
        name: "vendors-inventory-inbound-create"
      }, {
        path: "withdrawal-requests/create",
        component: _aadbe1c0,
        name: "vendors-inventory-withdrawal-requests-create"
      }, {
        path: "dispose-donate/:id?",
        component: _3bfab999,
        name: "vendors-inventory-dispose-donate-id"
      }, {
        path: "inbound/:id",
        component: _d6930f3a,
        name: "vendors-inventory-inbound-id"
      }, {
        path: "transfer/:id",
        component: _53855732,
        name: "vendors-inventory-transfer-id"
      }, {
        path: "withdrawal-requests/:id?",
        component: _15536946,
        name: "vendors-inventory-withdrawal-requests-id"
      }]
    }, {
      path: "notifications",
      component: _76b661da,
      name: "vendors-notifications"
    }, {
      path: "orders",
      component: _263bae26,
      name: "vendors-orders"
    }, {
      path: "payments",
      component: _320884ab,
      children: [{
        path: "",
        component: _a7a8c224,
        name: "vendors-payments"
      }, {
        path: "commission",
        component: _052877ff,
        name: "vendors-payments-commission"
      }, {
        path: "payouts",
        component: _0b24e729,
        name: "vendors-payments-payouts"
      }, {
        path: "statements",
        component: _ebf3300a,
        name: "vendors-payments-statements"
      }]
    }, {
      path: "products",
      component: _25756f36,
      name: "vendors-products"
    }, {
      path: "promotions",
      component: _264f5eb1,
      name: "vendors-promotions"
    }, {
      path: "samples",
      component: _604f8384,
      name: "vendors-samples"
    }, {
      path: "settings",
      component: _6d211064,
      name: "vendors-settings"
    }, {
      path: "stores",
      component: _6538da9a,
      name: "vendors-stores"
    }, {
      path: "widgets",
      component: _03e868be,
      name: "vendors-widgets"
    }, {
      path: "claims/create",
      component: _ee3ae574,
      name: "vendors-claims-create"
    }, {
      path: "products/create",
      component: _39cfe20e,
      name: "vendors-products-create"
    }, {
      path: "promotions/create",
      component: _695e7ba6,
      name: "vendors-promotions-create"
    }, {
      path: "promotions/draft",
      component: _4347b000,
      name: "vendors-promotions-draft"
    }, {
      path: "settings/general",
      component: _673d420c,
      name: "vendors-settings-general"
    }, {
      path: "settings/invite-colleagues",
      component: _15ec950c,
      name: "vendors-settings-invite-colleagues"
    }, {
      path: "settings/minimums",
      component: _9adfbbfa,
      name: "vendors-settings-minimums"
    }, {
      path: "settings/notifications",
      component: _6333e77a,
      name: "vendors-settings-notifications"
    }, {
      path: "settings/payments",
      component: _4d55fb0b,
      name: "vendors-settings-payments"
    }, {
      path: "orders/canceled/:id?",
      component: _3c3a4ec0,
      name: "vendors-orders-canceled-id"
    }, {
      path: "brands/:id",
      component: _5db8edf5,
      name: "vendors-brands-id"
    }, {
      path: "claims/:id",
      component: _56a20ce0,
      name: "vendors-claims-id"
    }, {
      path: "orders/:id",
      component: _0d0b020e,
      name: "vendors-orders-id"
    }, {
      path: "products/:id",
      component: _b437bfe6,
      children: [{
        path: "",
        component: _35c9a8d0,
        name: "vendors-products-id"
      }, {
        path: "request-change",
        component: _74e356c0,
        name: "vendors-products-id-request-change"
      }, {
        path: "skus",
        component: _f2dec82a,
        name: "vendors-products-id-skus"
      }, {
        path: "skus/create",
        component: _73372c33,
        name: "vendors-products-id-skus-create"
      }, {
        path: "skus/:sid",
        component: _52469b89,
        name: "vendors-products-id-skus-sid"
      }]
    }, {
      path: "promotions/:id",
      component: _4ed75959,
      name: "vendors-promotions-id"
    }, {
      path: "samples/:id",
      component: _87868fb4,
      name: "vendors-samples-id"
    }]
  }, {
    path: "/welcome-vendor",
    component: _b5f23ca6,
    name: "welcome-vendor"
  }, {
    path: "/brands/invite",
    component: _557c8282,
    name: "brands-invite"
  }, {
    path: "/capital/plus",
    component: _1d987efb,
    name: "capital-plus"
  }, {
    path: "/capital/term-loans",
    component: _2f8cf8fa,
    name: "capital-term-loans"
  }, {
    path: "/pricing/earnings-calculator",
    component: _f988989a,
    name: "pricing-earnings-calculator"
  }, {
    path: "/services/brands",
    component: _6c385a94,
    name: "services-brands"
  }, {
    path: "/services/retailers",
    component: _153fc42c,
    name: "services-retailers"
  }, {
    path: "/services/brands/direct",
    component: _56058b35,
    name: "services-brands-direct"
  }, {
    path: "/services/brands/express",
    component: _4cf05158,
    name: "services-brands-express"
  }, {
    path: "/",
    component: _3ffd8cb0,
    name: "index"
  }, {
    path: "/invoices/buyers/orders/:id?",
    component: _e4e5c7e0,
    name: "invoices-buyers-orders-id"
  }, {
    path: "/invoices/buyers/samples/:id?",
    component: _4bfdde64,
    name: "invoices-buyers-samples-id"
  }, {
    path: "/invoices/headBuyer/orders/:id?",
    component: _e653b76a,
    name: "invoices-headBuyer-orders-id"
  }, {
    path: "/invoices/headBuyer/samples/:id?",
    component: _35d5dd89,
    name: "invoices-headBuyer-samples-id"
  }, {
    path: "/invoices/lp/order/:id",
    component: _1e983450,
    name: "invoices-lp-order-id"
  }, {
    path: "/invoices/vendors/orders/:id?",
    component: _a5a012da,
    name: "invoices-vendors-orders-id"
  }, {
    path: "/invoices/vendors/samples/:id?",
    component: _20b65441,
    name: "invoices-vendors-samples-id"
  }, {
    path: "/invoices/lp/order/:id?/invoice",
    component: _d64870aa,
    name: "invoices-lp-order-id-invoice"
  }, {
    path: "/invoices/lp/order/:id?/slip",
    component: _6cee1532,
    name: "invoices-lp-order-id-slip"
  }, {
    path: "/blog/:slug",
    component: _d54cdb12,
    name: "blog-slug"
  }, {
    path: "/brands/:id",
    component: _c14564de,
    name: "brands-id"
  }, {
    path: "/products/:id",
    component: _0fef08a9,
    name: "products-id"
  }, {
    path: "/:slug-b:id(\\d+).html",
    component: _cf6c7d96,
    name: "_brand"
  }, {
    path: "/:slug-p:id(\\d+).html",
    component: _db92e1c6,
    name: "_product"
  }, {
    path: "/setup-password/:user_role",
    component: _224ce406,
    name: "_setup_password"
  }, {
    path: "/vendor/vendor_auth/invitation/accept",
    component: _16c8cba8,
    name: "_vendor_accept_invitation"
  }, {
    path: "/buyer/buyer_auth/invitation/accept",
    component: _16c8cba8,
    name: "_buyer_accept_invitation"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
